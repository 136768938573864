import Button from '@mui/material/Button';
import { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { inviteUser } from '@/actions/invitation';
import { notify } from '@/actions/ui';
import AddToNetworkDialog from '@/components/AddToNetworkDialog';
import Alert from '@/components/Alert';
import FAIcon from '@/components/Icon/FAIcon';
import InternalNetworkShareButton from '@/components/InternalNetworkShareButton';
import InternalNetworkShareSingleProfile from '@/components/InternalNetworkShareSingleProfile';
import LongList from '@/components/LongList';
import AddToExpertRequestButton from '@/profile/components/AddToExpertRequestButton';
import { fetchProfile } from '@/profile/store';
import { Query } from '@/search';
import SearchPromo from '@/search/components/SearchPromo';
import { clearProfileSelection, search, selectProfile } from '@/search/store';
import { RootState } from '@/store';
import { fetchUser } from '@/store/user';

import AddProfileKeywordsButton from './AddProfileKeywordsButton';
import s from './Experts.module.scss';
import Filters from './Filters/Filters';
import ProfileSearchResult from './ProfileSearchResult/ProfileSearchResult';
import SelectedProfilesBar from './SelectedProfilesBar';

interface ExpertsProps {
  totalFiltersApplied: number;
}

const connector = connect(
  (state: RootState) => {
    const { viewer, groups } = state;
    const query = new Query(state.search.query);
    const search = state.search.queries[query.hash()] || {};

    const networkGroups =
      groups.networks && groups.networks.edges && groups.networks.edges.map((e: any) => e.node);

    const internalNetworks =
      networkGroups && networkGroups.map((g: any) => g.internal_network).filter(Boolean);

    return {
      viewer,
      edges: search.edges,
      pageInfo: search.pageInfo,
      fetching: search.fetching,
      query: state.search.query,
      error: state.search.error,
      selectedProfiles: state.search.selectedProfiles,
      internalNetworks,
    };
  },
  {
    search,
    selectProfile,
    clearProfileSelection,
    inviteUser,
    notify,
    fetchUser,
    fetchProfile,
  }
);

const Experts = ({
  viewer,
  edges = [],
  pageInfo = {
    hasNextPage: false,
    total: 0,
  },
  fetching,
  query,
  error,
  selectedProfiles,
  internalNetworks,
  search,
  selectProfile,
  clearProfileSelection,
  inviteUser,
  notify,
  fetchUser,
  fetchProfile,
  totalFiltersApplied,
}: ExpertsProps & ConnectedProps<typeof connector>) => {
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [profileShare, setProfileShare] = useState<any | null | undefined>(undefined);

  const handleMore = useCallback(() => {
    search(new Query(query), edges[edges.length - 1].cursor);
  }, [edges, query, search]);

  const showInviteDialog = useCallback(() => setOpenInviteDialog(true), []);

  const hideInviteDialog = useCallback(() => setOpenInviteDialog(false), []);

  const handleAddToNetwork = useCallback(
    async ({
      networkId,
      value: { id, firstName, lastName, email },
      teamNote,
      invitationMessage,
    }: any) => {
      try {
        await inviteUser({
          collectionType: 'internal_network_experts',
          collectionId: networkId,
          email,
          profileId: id,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          role: 'expert',
          teamNote,
          invitationMessage,
        });

        notify(!id ? 'Invite sent!' : 'Add sent');
      } catch (e) {
        console.warn(e);
        notify('Error while adding/inviting experts', 'error');
      }
    },
    [inviteUser, notify]
  );

  const handleSelectProfile = useCallback(
    (profile: any, selected: any) => selectProfile(profile, selected),
    [selectProfile]
  );

  const handleProfileShare = useCallback(
    async (urlEndpoint: any) => {
      if (!viewer.admin) {
        await fetchUser(viewer.username, {
          groups: true,
          sharedInternalNetworks: true,
        });
      }

      const profileShare = await fetchProfile(urlEndpoint, {
        force: true,
        internalNetworks: true,
      });

      setProfileShare(profileShare);
    },
    [fetchProfile, fetchUser, viewer.admin, viewer.username]
  );

  const handleProfileShareClose = useCallback(() => setProfileShare(null), []);

  const results = edges.map((e) => e.node);

  const hasInternalNetworks = internalNetworks && internalNetworks.length > 0;

  return (
    <div className={s.root}>
      <div className={s.filters}>
        <Filters totalFiltersApplied={totalFiltersApplied} />
      </div>
      <div className={s.results}>
        {error && viewer.admin && <Alert type="error">{error}</Alert>}

        <LongList
          infiniteScroll
          entityName={viewer.admin ? 'Profile' : 'Expert'}
          entityNamePlural={viewer.admin ? 'Profiles' : 'Experts'}
          hasNextPage={pageInfo.hasNextPage}
          onMore={handleMore}
          total={pageInfo.total || 0}
          loading={fetching}
          action={
            hasInternalNetworks && (
              <Button
                color="secondary"
                startIcon={<FAIcon icon="user-plus" size={16} />}
                onClick={showInviteDialog}
              >
                Invite Experts
              </Button>
            )
          }
        >
          {results.map((r: any) => (
            <ProfileSearchResult
              selectable
              key={`result-${r.profile.id}`}
              result={r}
              style={{ marginBottom: 20 }}
              selected={selectedProfiles.some((p: any) => p.id === r.profile.id)}
              onSelect={handleSelectProfile}
              showSource={!viewer.admin}
              showNetworks={viewer.admin}
              onProfileShare={() => handleProfileShare(r.profile.url_endpoint)}
              showAdditionalInformation={viewer.admin}
            />
          ))}
        </LongList>
        <SearchPromo
          style={{ marginTop: 40 }}
          title="Don’t see who you’re looking for?"
          label="Find Experts"
          href="/request_expert"
        >
          <p>
            Access the OnFrontiers expert marketplace to grow your organization’s knowledge network
          </p>
        </SearchPromo>
        {hasInternalNetworks && (
          <AddToNetworkDialog
            open={openInviteDialog}
            networks={internalNetworks}
            onAdd={handleAddToNetwork}
            onDone={hideInviteDialog}
            onlyInvites={!viewer.admin}
          />
        )}
      </div>
      <SelectedProfilesBar
        onRemove={(profile: any) => selectProfile(profile, false)}
        onClear={() => clearProfileSelection()}
        profiles={selectedProfiles}
      >
        <AddToExpertRequestButton
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          color="teal"
          icon={false}
          profiles={selectedProfiles}
          style={{ marginRight: 10, textTransform: 'initial' }}
          onExpertAdd={() => clearProfileSelection()}
          form="addExpertsToExpertRequest"
          size="medium"
        />
        <AddProfileKeywordsButton
          profiles={selectedProfiles}
          size="medium"
          style={{ marginRight: 10, textTransform: 'initial' }}
        />
        <InternalNetworkShareButton
          profiles={selectedProfiles}
          size="medium"
          style={{ marginRight: 10, textTransform: 'initial' }}
        />
      </SelectedProfilesBar>
      {profileShare && (
        <InternalNetworkShareSingleProfile
          open
          onClose={handleProfileShareClose}
          profile={profileShare}
        />
      )}
    </div>
  );
};

export default connector(Experts);
