import { Field, Form } from 'react-final-form';

import Dialog from '@/components/Dialog';
import { Checkbox, TextField } from '@/components/FormAdapters';
import { isEmailValid } from '@/utils';

function InviteParticipant({
  viewer,
  consultation,
  open,
  onConfirm,
  onCancel,
  title = 'Invite Participant',
}: any): JSX.Element {
  const isRequester = consultation?.requester?.id === viewer?.id;
  const group = consultation?.group || consultation?.expert_request?.project?.group;
  const groupName = group?.name;

  const validate = (values: any) => {
    const errors = {};

    if (!values.name || !values.name.trim()) {
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type '{}'.
      errors.name = 'Required';
    } else if (values.name.trim().length > 100) {
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type '{}'.
      errors.name = 'Value too long';
    }
    if (!values.email) {
      // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type '{}'.
      errors.email = 'Required';
    } else if (!isEmailValid(values.email)) {
      // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type '{}'.
      errors.email = 'Email must be valid.';
    }

    return errors;
  };

  return (
    <Form validate={validate} onSubmit={onConfirm} initialValues={{}}>
      {({ form, handleSubmit }) => {
        const handleReset = () => {
          form.reset();
          onCancel();
        };

        const onSubmit = async (e: any) => {
          e.preventDefault();
          const error = await handleSubmit(e);
          if (error || form.getState().hasValidationErrors) return error;
          form.reset();
        };

        return (
          <Dialog
            useForm
            open={open}
            title={title}
            confirmLabel="Yes, Invite"
            onClose={handleReset}
            onCancel={handleReset}
            onSubmit={onSubmit}
          >
            <Field component={TextField} fullWidth name="name" label="Name" changeOnBlur={false} />
            <Field
              component={TextField}
              fullWidth
              name="email"
              label="Email"
              changeOnBlur={false}
            />
            {isRequester && group && (
              <Field
                type="checkbox"
                component={Checkbox}
                name="inviteJoinTeam"
                label={`Invite to Join Team: ${groupName}`}
              />
            )}
          </Dialog>
        );
      }}
    </Form>
  );
}

export default InviteParticipant;
