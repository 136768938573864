import { MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import React from 'react';

import Button from '@/components/Button/Button';
import FAIcon from '@/components/Icon/FAIcon';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import IconMenu from '@/components/IconMenu/IconMenu';
import Link from '@/components/Link/Link';
import Tidbit from '@/components/Tidbit/Tidbit';
import { black, borderColor, darkGray, darkGreen } from '@/theme/colors';
import { unique } from '@/utils';

const getStyles = makeStyles(() => ({
  network: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: 20,
    border: `1px solid ${borderColor}`,
  },
  networkIcon: {
    flex: '0 0 auto',
  },
  networkInfo: {
    flex: 1,
    paddingLeft: 20,
  },
  networkTitle: {
    color: black,
    fontSize: 20,
    fontWeight: 500,
  },
  aboutLink: {
    color: darkGreen,
    fontWeight: 400,
    fontSize: 12,
    verticalAlign: 'middle',
    marginLeft: 10,
  },
  consultations: {
    fontSize: 14,
  },
  tidbit: {
    margin: '5px 0 10px',
  },
  actions: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
}));

interface NetworkProps {
  className?: string;
  networkExpert: any;
  active?: boolean;
}

const Network = ({ className, networkExpert, active }: NetworkProps): JSX.Element => {
  const s = getStyles();

  const { network, consultations } = networkExpert;

  const uniqueConsultations =
    consultations && unique(consultations, (c: any) => c.requester && c.requester.name);

  return (
    <div className={cx(s.network, className)}>
      <div className={s.networkIcon}>
        <MaterialIcon icon="business" color={darkGray} size={40} />
      </div>
      <div className={s.networkInfo}>
        <div className={s.networkTitle}>
          {network.name}
          {network.group.about_page && (
            <Link newTab className={s.aboutLink} to={network.group.about_page.html_url}>
              Learn more
              <FAIcon
                iconSet="far"
                icon="external-link"
                size={12}
                color={darkGreen}
                style={{ marginLeft: 5 }}
              />
            </Link>
          )}
        </div>
        {network.group && (
          <Tidbit
            className={s.tidbit}
            inline={false}
            city={network.group.city}
            country={network.group.country}
          />
        )}
        {uniqueConsultations && uniqueConsultations.length > 0 && (
          <div className={s.consultations}>
            {uniqueConsultations.map((c: any, i: any) => (
              <React.Fragment key={c.id}>
                <Link to={c.html_url}>
                  {c.requester ? c.requester.name : 'Confidential Client'}
                </Link>
                {i < uniqueConsultations.length - 1 ? ', ' : ''}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      {active ? (
        <IconMenu>
          <Link to={`/network/${networkExpert.id}/removed`} menu>
            <MenuItem>Remove Connection</MenuItem>
          </Link>
        </IconMenu>
      ) : (
        <div className={s.actions}>
          <Link to={`/network/${networkExpert.id}/rejected`}>
            <Button size="small" style={{ marginRight: 10 }}>
              Reject
            </Button>
          </Link>
          <Link to={`/network/${networkExpert.id}/active`}>
            <Button size="small">Accept</Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Network;
