import cx from 'classnames';
import pluralize from 'pluralize';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { money } from '@/core/money';
import { formatCreditsText, formatDate } from '@/utils';

import Button from '../Button';
import Link from '../Link';
import TransactionList from '../TransactionList';
import s from './CreditSettings.module.scss';

interface CreditSettingsProps {
  account?: any;
  accountType?: string;
  memberNotOwner?: boolean;
  seatCount?: number;
  purchasePath: string;
}

const CreditSettings = ({
  account = {},
  accountType,
  memberNotOwner = false,
  seatCount,
  purchasePath,
}: CreditSettingsProps): JSX.Element => {
  const navigate = useNavigate();

  const transactions = account.transactions || [];
  const balance = account.credit_balance || money({ currency: 'OFC', cents: 0 });

  const entityType = account.entity_type || 'organization';

  const handlePurchaseCredits = useCallback(() => {
    navigate(purchasePath);
  }, [navigate, purchasePath]);

  const expiries = (account.purchases || []).filter(
    (p: any) => money(p.remaining.cents).cents && p.expires_at
  );

  return (
    <div>
      <div className={s.details}>
        <div className={s.detailsRow}>
          <div className={s.detailsCol1}>Balance</div>
          <div
            className={cx(s.detailsCol2, {
              [s.balanceWarning]: balance.cents <= 0,
            })}
          >
            {money(balance).formatted()} {pluralize('Credit', balance.cents / 100)}
          </div>
          <div className={s.detailsCol3}>
            {memberNotOwner ? (
              <div>Contact Team Owner to purchase credits</div>
            ) : (
              <Button size="small" label="Purchase Credits" onClick={handlePurchaseCredits} />
            )}
          </div>
        </div>
        {accountType && (
          <div className={s.detailsRow}>
            <div className={s.detailsCol1}>Account Type</div>
            <div className={s.detailsCol2}>{accountType}</div>
          </div>
        )}
        {entityType === 'organization' && (
          <div className={s.detailsRow}>
            <div className={s.detailsCol1}>Seat Count</div>
            <div className={s.detailsCol2}>{seatCount || 0}</div>
          </div>
        )}
        <div className={s.detailsRow}>
          <div className={s.detailsCol1}>Credit Expiry</div>
          <div className={s.detailsCol2}>
            {expiries.length ? (
              <div>
                {expiries.map((e: any, i: number) => (
                  <div key={i}>
                    {formatCreditsText(money(e.remaining).cents)} on {formatDate(e.expires_at)}
                  </div>
                ))}
              </div>
            ) : (
              'N/A'
            )}
          </div>
        </div>
      </div>
      <TransactionList
        transactions={transactions}
        amountColumn={{
          header: 'Credits Amount',
          field: 'credits',
        }}
      />
      <div className={s.questions}>
        Questions about credit usage? Contact our{' '}
        <Link href="https://try.onfrontiers.com/knowledge">customer care team</Link>.
      </div>
    </div>
  );
};

export default CreditSettings;
