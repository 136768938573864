import { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { RootState } from '@/store';
import { fetchUser } from '@/store/user';

import Button, { ButtonProps } from '../Button';
import InternalNetworkShareMultipleProfiles from './InternalNetworkShareMultipleProfiles';

interface InternalNetworkShareButtonProps extends ButtonProps {
  profile?: any;
  profiles?: any[];
  style?: any;
  onExpertShare?: () => void;
}

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
  }),
  {
    fetchUser,
  }
);

const InternalNetworkShareButton = ({
  viewer,
  fetchUser,
  profile,
  profiles,
  style,
  onExpertShare,
  ...other
}: InternalNetworkShareButtonProps & ConnectedProps<typeof connector>): JSX.Element | null => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpen = useCallback(async () => {
    if (!viewer.admin) {
      await fetchUser(viewer.username, {
        groups: true,
        sharedInternalNetworks: true,
      });
    }

    setDialogOpen(true);
  }, [fetchUser, viewer.admin, viewer.username]);

  const handleClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const allProfiles = profile ? [profile] : profiles || [];
  if (allProfiles.length === 0) return null;

  return (
    <div>
      <Button
        size="small"
        {...other}
        style={{
          fontWeight: 'bold',
          letterSpacing: '-0.5px',
          ...style,
        }}
        onClick={handleOpen}
      >
        Share
      </Button>
      <InternalNetworkShareMultipleProfiles
        open={dialogOpen}
        profiles={allProfiles}
        onClose={handleClose}
        //onExpertShare={onExpertShare}
      />
    </div>
  );
};

export default connector(InternalNetworkShareButton);
