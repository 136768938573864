import Grid from '@mui/material/Grid';
import { FC, useCallback, useMemo } from 'react';
import { Field, useForm } from 'react-final-form';

import { Viewer } from '@/core/viewer';
import { normalizeNewLines, normalizeSpace, sortBy, unique } from '@/utils';

import ConsultationDates from '../ConsultationDates';
import DurationField from '../DurationField';
import { Select, TextField } from '../FormAdapters';

interface FieldsProps {
  viewer: Viewer;
  profile: any;
  expertRequests: any;
  groups: any;
  groupId: string;
  expertRequest: any;
}

const Fields: FC<FieldsProps> = ({
  viewer,
  profile,
  expertRequests,
  groups,
  groupId,
  expertRequest,
}) => {
  const form = useForm();

  const project = expertRequest && expertRequest.project;
  const members = project && project.members;
  const viewerGroups = viewer.groups || [];
  const group =
    viewerGroups.filter((grp: any) => grp.id === groupId)[0] || (project && project.group);
  const groupNodes = groups.edges.map((e: any) => e.node);
  const allGroups = group ? unique(groupNodes.concat(group), (g: any) => g.id) : groupNodes;

  const memberOptions = useMemo(
    () =>
      (members || [])
        .filter(
          (m: any) => m.user && m.state === 'active' && (m.role === 'owner' || m.role === 'member')
        )
        .map((m: any) => ({
          value: m.user.id,
          label: m.user.name,
        })),
    [members]
  );

  const disclosureOptions = useMemo(
    () => [
      { value: 'full', label: 'Share my company information with Experts' },
      { value: 'private', label: 'Do not share my name with Experts' },
    ],
    []
  );

  const sortedGroups = useMemo(
    () =>
      allGroups
        .map((g: any) => ({
          value: g.id,
          label: g.name,
        }))
        .sort(sortBy('label')),
    [allGroups]
  );

  const sortedRequests = useMemo(
    () =>
      expertRequests.edges
        .map((e: any) => ({
          value: e.node.id,
          label: e.node.name,
        }))
        .sort(sortBy('label')),
    [expertRequests.edges]
  );

  const validateGroup = useCallback(
    (groupId: any, values: any) => (groupId ? values.marketplace_error : 'Required'),
    []
  );

  // Heuristics to avoid visible layout changes when showing/hiding components
  // on dialog open
  const groupsVisible = viewer.admin || viewerGroups.length > 1;
  const expertRequestsVisible =
    !expertRequest && (!expertRequests.loaded || expertRequests.edges.length > 0);

  const confidentiality = (
    <Field
      component={Select}
      name="disclosure"
      label="Confidentiality"
      options={disclosureOptions}
    />
  );

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="description"
          multiline
          label={`Include a note to ${profile.first_name}`}
          style={{ marginTop: 0 }}
          onKeyDown={(e: any) => e.keyCode === 13 && e.preventDefault()}
          onKeyUp={(e: any) => form.change('description', normalizeNewLines(e.target.value))}
        />
      </Grid>

      <Grid item xs={12}>
        <DurationField
          name="duration"
          minHours={1}
          maxHours={viewer.admin ? undefined : 10}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <ConsultationDates
          name="dates"
          viewer={viewer}
          userName={profile.first_name}
          userTimezone={profile.timezone}
          label="Select a deadline"
          style={{ marginTop: 20 }}
        />
      </Grid>

      {expertRequestsVisible && (
        <Grid item xs={12}>
          <Field
            component={Select}
            name="expert_request_id"
            label="Add to Expert Request"
            options={sortedRequests}
            autocomplete={sortedRequests.length > 10}
            includeEmpty
            placeholder="Start typing the name of an expert request"
          />
        </Grid>
      )}

      {groupsVisible && (
        <Grid item sm={7} xs={12} style={{ paddingRight: 20 }}>
          <Field
            component={Select}
            name="group_id"
            label="Add to team"
            options={sortedGroups}
            autocomplete={sortedGroups.length > 10}
            // this option is only enabled for a direct consultation
            disabled={!!group && !!expertRequest}
            includeEmpty
            validate={validateGroup}
            placeholder="Start typing the name of a team"
          />
        </Grid>
      )}

      <Grid item sm={groupsVisible ? 5 : 12} xs={12}>
        <Field
          component={TextField}
          name="tracking_code"
          label="Tracking Code"
          inputProps={{ maxLength: 60 }}
          parse={(v) => normalizeSpace(v)}
        />
      </Grid>

      {viewer.admin && memberOptions.length > 0 && (
        <Grid item xs={12}>
          <Field
            component={Select}
            name="requester_id"
            label="Request written engagement on behalf of"
            options={memberOptions}
            includeEmpty
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {confidentiality}
      </Grid>
    </Grid>
  );
};

export default Fields;
