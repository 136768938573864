import createDOMPurify, { Config } from 'dompurify';
import { PhoneNumberUtil } from 'google-libphonenumber';
import keycode from 'keycode';
import moment, { Moment } from 'moment-timezone';
import queryString from 'query-string';

import config from '@/config';
import Duration from '@/core/duration';
import { Viewer } from '@/core/viewer';
import { CollectionState } from '@/reducers/projects';

const DOMPurify = createDOMPurify(window);

// From backbone
let idCounter = 0;
export function uniqueId(prefix: string): string {
  const id = `${++idCounter}`;
  return prefix ? prefix + id : id;
}

const takeRate = 0.2;
const moneyPerCredit = 200;
const roundRate = 25;
const roundCredit = 5;

export function formatCredits(amount: number): string {
  return (amount / 100.0)
    .toFixed(2)
    .replace(/(\..*?)(0+)$/, '$1')
    .replace(/\.?$/, '');
}

export function formatCreditsText(amount: number): string {
  return `${formatCredits(amount)} ${amount / 100 === 1 ? 'credit' : 'credits'}`;
}

export function capitalize(str: string): string {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
}

export function absoluteUrl(path: string): string {
  return new URL(path, window.location.href).href;
}

// same rule used to create transactions
export function roundOffCredits(creditRate = 0): number {
  return roundCredit * Math.ceil(creditRate / roundCredit);
}

/**
 * Computes the number of credits from a bill rate
 * @param {int} bill rate in cents
 */
export function rateToCredits(rate = 0): number {
  const totalRate = rate / (1.0 - takeRate);
  const n = Math.trunc(totalRate / moneyPerCredit);
  let d = Math.floor(n / roundRate);

  if (n % roundRate > 0) {
    d++;
  }

  return d * roundRate;
}

export function formatBillRate(rate = 0): string {
  return `$${(rate / 100).toFixed(2)} per hour`;
}

export function formatLocation(city?: string, country?: string): string {
  return [city, country].filter((e) => e).join(', ');
}

export const dateFormat = 'dddd D MMM YYYY';

export function formatDateTime(date: Moment | Date, timezone?: string): string {
  const momentTimezone = moment.tz(
    date,
    timezone && moment.tz.zone(timezone) ? timezone : moment.tz.guess()
  );
  return `${momentTimezone.format(dateFormat)} • ${momentTimezone.format('h:mm a')}`;
}

export function formatDate(date: Moment | Date, timezone: string = ''): string {
  const momentTimezone = moment.tz(date, moment.tz.zone(timezone) ? timezone : moment.tz.guess());
  return momentTimezone.format(dateFormat);
}

let _localStorage: Storage | null | undefined;
function localStorage() {
  if (typeof _localStorage !== 'undefined') {
    return _localStorage;
  }

  _localStorage = typeof window !== 'undefined' && window.localStorage ? window.localStorage : null;

  if (_localStorage) {
    try {
      _localStorage.setItem('localStorage', '1');
      _localStorage.removeItem('localStorage');
    } catch {
      console.warn('browser does not support local storage');
      _localStorage = undefined;
    }
  }

  if (!_localStorage) {
    _localStorage = null;
  }

  return _localStorage;
}

export function getCache(key: string): any {
  const storage = localStorage();
  if (storage && storage[key]) {
    return JSON.parse(storage[key]);
  }
}

export function setCache(key: string, obj: any): void {
  const storage = localStorage();
  if (storage) {
    if (obj) {
      storage[key] = JSON.stringify(obj);
    } else {
      delete storage[key];
    }
  }
}

export function clearCache(key: string): void {
  setCache(key, undefined);
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function isEmailValid(email: string): boolean {
  return emailRegex.test(email);
}

export function isPhoneValid(phone: string): boolean {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(phone));
  } catch {
    return false;
  }
}

export function normalizePhone(value = ''): string {
  return value.replace(/[^\d-\s+()]/g, '');
}

// Replace multiple spaces with a single space
// also remove a single space at the beginning
export function normalizeSpace(value = ''): string {
  return value.replace(/^\s+|\s+(?=\s)/g, '');
}

// Replace new lines with an empty string
export function normalizeNewLines(v = ''): string {
  return v.replace(/\n/g, '');
}

export function urljoin(base: string, input: string): string {
  try {
    new URL(input);
    return input;
    // eslint-disable-next-line no-empty
  } catch {}

  const url = new URL(base);
  const { pathname } = url;
  const path = input.startsWith('/') ? input : `${pathname}/${input}`;

  return url.origin + path;
}

export function debounce(f: Function, wait: number) {
  let timeout: string | number | NodeJS.Timeout | null | undefined;
  let toCall: (() => void) | null;
  return function (this: unknown, ...args: any[]) {
    toCall = () => {
      f.apply(this, args);
      toCall = null;
    };
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      if (!toCall) return;
      toCall();
    }, wait);
  };
}

export function debounceAsync(func: Function, wait: number) {
  let timeout: string | number | NodeJS.Timeout | null | undefined;

  return (...args: any[]) =>
    new Promise((resolve, reject) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(async () => {
        try {
          const result = await func(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, wait);
    });
}

export function slugify(string: string): string {
  return (string || '')
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

export function shouldResetCollection(
  col: CollectionState = {
    loading: false,
    edges: [],
    pageInfo: { hasNextPage: true },
    resetAt: undefined,
  },
  pageSize: number,
  expiryInMinutes?: number
): boolean {
  const shouldReset = col.edges.length < pageSize && col.pageInfo.hasNextPage;
  const expired = moment(col.resetAt)
    .add(expiryInMinutes || 5, 'minutes')
    .isBefore(moment());
  return shouldReset || expired;
}

const UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function prettyBytes(num: number): string {
  if (!Number.isFinite(num)) {
    throw new TypeError(`Expected a finite number, got ${typeof num}: ${num}`);
  }

  const neg = num < 0;

  let n = num;
  if (neg) {
    n = -n;
  }

  if (n < 1) {
    return `${(neg ? '-' : '') + n} B`;
  }

  const exponent = Math.min(Math.floor(Math.log(n) / Math.log(1000)), UNITS.length - 1);
  const numStr = Number((n / 1000 ** exponent).toPrecision(3));
  const unit = UNITS[exponent];

  return `${(neg ? '-' : '') + numStr} ${unit}`;
}

export function pathAndQuery(location: URL): string {
  return encodeURIComponent(`${location.pathname}${location.search}`);
}

export function getSameOriginPath(urlStr: string): string {
  try {
    const url = new URL(urlStr);
    if (window.location.origin === url.origin) {
      return url.pathname;
    }
  } catch {
    return '';
  }
  return '';
}

export function rewriteUrl(url: string): string {
  const toRewrite = config.rewriteUrl;
  if (toRewrite && url?.startsWith(toRewrite)) {
    return url.slice(toRewrite.length);
  }
  return url;
}

export function queryPart(opts: Record<string, any>): string {
  const qstr = queryString.stringify(opts);
  return qstr ? `?${qstr}` : '';
}

export function safeHtml(str: string, opts: Config): string {
  return DOMPurify.sanitize(unescape(str), opts);
}

export function safeUrl(str: string): string {
  try {
    const url = new URL(str);
    if (url.protocol === 'http:' || url.protocol === 'https:') {
      return str;
    }
    // eslint-disable-next-line no-empty
  } catch {}
  return '';
}

interface HighlightOptions extends Config {
  multiline?: boolean;
}

export function highlight(str: string, { multiline, ...opts }: HighlightOptions = {}): string {
  return safeHtml(multiline ? str.split('\n').join('<br />') : str, {
    ALLOWED_TAGS: ['em', 'br'],
    ALLOWED_ATTR: [],
    ...opts,
  });
}

export function groupBy<T, K extends string | number | symbol>(
  list: T[],
  keyGetter: (item: T) => K
): Record<K, T[]> {
  const grouped: Record<K, T[]> = {} as Record<K, T[]>;

  list.forEach((item) => {
    const key = keyGetter(item);
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(item);
  });

  return grouped;
}

export function unique<T, K extends string | number | symbol>(
  list: T[],
  keyGetter: (item: T) => K
): T[] {
  // groupBy is presumed to be the generic function from the earlier example.
  const grouped = groupBy(list, keyGetter);
  return Object.values(grouped).map((group: any) => group[0]);
}

export function sortBy(field: string) {
  return function (p1: { [key: string]: any }, p2: { [key: string]: any }): number {
    const n1 = (p1[field] || '').trim().toLowerCase();
    const n2 = (p2[field] || '').trim().toLowerCase();
    if (n1 < n2) return -1;
    if (n1 > n2) return +1;
    return 0;
  };
}

export function fibonacci(start: number, count: number): number {
  let lastButOne = 0;
  let last = 0;
  let current = start;

  for (let i = 1; i < count; i++) {
    lastButOne = last;
    last = current;
    current = last + lastButOne;
  }

  return current;
}

// based on https://stackoverflow.com/a/48218209
// but for makeStyles
export function concatDeep(...objects: { [key: string]: any }[]) {
  const isObject = (obj: object) => obj && typeof obj === 'object';

  return objects.reduce((acc, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = acc[key];
      const oVal = obj[key];

      if (isObject(pVal) && isObject(oVal)) {
        acc[key] = concatDeep(pVal, oVal);
      } else {
        acc[key] = `${pVal} ${oVal}`;
      }
    });

    return acc;
  }, {});
}

export function formatDuration(duration: Duration, separator = ':'): string {
  if (!duration) return '';
  const hours = `${duration.hours()}`.padStart(2, '0');
  const minutes = `${duration.minutes() % 60}`.padStart(2, '0');
  return `${hours}${separator}${minutes}`;
}

export function isArrayNotEmpty<T>(a: T[]): boolean {
  return a.length > 0;
}

export function prettyName(name: string | undefined): string {
  if (!name) return '';
  return (name.split('.').pop() ?? '')
    .split('_')
    .reduce((text: string, t: string) => `${text} ${t.charAt(0).toUpperCase()}${t.slice(1)}`, '')
    .trim();
}

export function isBot(ua: UAParser.IResult): boolean {
  return !!ua.browser.type;
}

export function isUserApplying(user: Viewer): boolean {
  return !user.expert_state || user.expert_state === 'applying';
}

export function interceptEnter(e: any): void {
  const target = e.target;
  if (keycode(e) !== 'enter' || target.type === 'textarea') return;
  e.preventDefault();
}

export function isEmpty(value: any): boolean {
  // Always trim and zeros are valid
  return !String(value ?? '').trim();
}

export function normalise(value: number, max: number): number {
  return value > max ? 100 : (value * 100) / max;
}

// Get the id property for a list of input values, useful for redux-form
// field parse property
export function parseId(values: { id: any }[]) {
  return values && values.map((value) => value.id).filter(Boolean);
}

// Common Field validation helpers
export function required(value: string) {
  return value ? undefined : 'Required';
}

// Error message helper
export function errorMessage(value: string): string {
  return value.replace('GraphQL Error: ', '');
}

export type HostnameParser = ReturnType<typeof createHostnameParser>;

export interface ParsedHostname {
  subdomain?: string;
  customTLD: string | null;
}

export function createHostnameParser(baseDomain: string) {
  const parts = baseDomain.split('.');
  const baseTLD = parts.length > 1 ? parts[parts.length - 1] : '';

  const domainExtractor = baseTLD ? new RegExp(`^(.+)\\.${baseTLD}$`) : /^(.*)$/;
  const domainMatch = domainExtractor.exec(baseDomain);
  const [, domainWithoutTLD] = domainMatch || [];
  if (!domainMatch || !domainWithoutTLD) {
    throw new Error('Invalid domain config. ' + `BASE_DOMAIN:'${baseDomain}', BASE_TLD:${baseTLD}`);
  }
  const domainParser = new RegExp(`^((.+)\\.)?${domainWithoutTLD}(\\.(.+))?$`);

  return (hostname: string): ParsedHostname => {
    const match = domainParser.exec(hostname);
    if (!match) return { customTLD: null };
    const [, , subdomain, , tld = ''] = match;
    const customTLD = tld === baseTLD ? null : tld;
    return { subdomain, customTLD };
  };
}

export const parseHostname = createHostnameParser(config.baseDomain);

export const fileToB64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
